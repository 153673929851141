import { useEffect, useState } from 'react';
import type { RealEstateAd } from '@/types/ads/RealEstateAd.ts';
import categorytest from '../../../../types/ads/categorytest.json' assert { type: 'json' };
import '../../CSS/RealEstatePage.css';
import { useNavigate, useLocation } from 'react-router-dom';
import Wishlist from '../../components/WishList/WishList.tsx';

const realEstateData: RealEstateAd[] = categorytest as unknown as RealEstateAd[];

export default function RealEstatePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn] = useState(false); // Simulated login state
  const [listings, setListings] = useState<RealEstateAd[]>([]);
  const [selectedListing, setSelectedListing] = useState<RealEstateAd | null>(
    location.state?.selectedListing || null,
  ); // Selected listing for popup
  const [likedItems, setLikedItems] = useState<RealEstateAd[]>(() => {
    const saved = localStorage.getItem('likedItems');
    return saved ? JSON.parse(saved) : [];
  });
  const [showWishlist, setShowWishlist] = useState(false);

  useEffect(() => {
    // Filter items with category "Real Estate" only
    const filteredListings = realEstateData.filter(
      (listing) => listing.metadata.category === 'Real Estate',
    );
    setListings(filteredListings);
    if (location.state?.isLoggedIn && location.state?.selectedListing) {
      setSelectedListing(location.state.selectedListing);
    }
  }, [location]);

  const handleItemClick = (listing: RealEstateAd) => {
    if (!isLoggedIn) {
      navigate('/auth/login', {
        state: { selectedListing: listing, redirectTo: '/real-estate' },
      });
    } else {
      setSelectedListing(listing);
    }
  };

  const handleLoveClick = (listing: RealEstateAd) => {
    const isLiked = likedItems.some((item) => item.metadata.id === listing.metadata.id);
    const updatedLikedItems = isLiked
      ? likedItems.filter((item) => item.metadata.id !== listing.metadata.id)
      : [...likedItems, listing];
    setLikedItems(updatedLikedItems);
    localStorage.setItem('likedItems', JSON.stringify(updatedLikedItems));
  };

  const closePopup = () => {
    setSelectedListing(null);
  };

  const toggleWishlist = () => {
    setShowWishlist(!showWishlist);
  };

  return (
    <div className="real-estate-page">
      <span className="wishlist-icon" onClick={toggleWishlist}>
        ❤
      </span>
      <h2>Saved Ads</h2>
      <h1>Real Estate Listings</h1>
      {showWishlist && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={toggleWishlist}>
              &times;
            </span>
            <Wishlist likedItems={likedItems} handleLoveClick={handleLoveClick} />
          </div>
        </div>
      )}
      <div className="listings">
        {listings.map((listing) => (
          <div
            key={listing.metadata.id}
            className="listing-item"
            onClick={() => handleItemClick(listing)}
          >
            <h2>{listing.metadata.title}</h2>
            <p>{listing.metadata.description}</p>
            <p>Price: ${listing.realEstateAd.price}</p>
            <p>
              Location: {listing.realEstateAd.location.city}, {listing.realEstateAd.location.state}
            </p>
            <span
              className={`love-icon ${likedItems.some((item) => item.metadata.id === listing.metadata.id) ? 'red' : ''}`}
              onClick={(e) => {
                e.stopPropagation();
                handleLoveClick(listing);
              }}
            >
              ❤
            </span>
          </div>
        ))}
      </div>

      {selectedListing && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closePopup}>
              &times;
            </span>
            <h2>{selectedListing.metadata.title}</h2>
            <p>{selectedListing.metadata.description}</p>
            <p>
              <strong>Price:</strong> ${selectedListing.realEstateAd.price}
            </p>
            <p>
              <strong>Location:</strong> {selectedListing.realEstateAd.location.city},{' '}
              {selectedListing.realEstateAd.location.state}
            </p>
            <p>
              <strong>Size:</strong> {selectedListing.realEstateAd.size} sq ft
            </p>
            <p>
              <strong>Amenities:</strong> {selectedListing.realEstateAd.amenities.join(', ')}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
