import React from 'react';
import { useState } from 'react';
import '../../CSS/EventAndEntertainmentServices.css';
import eventAndEntertainmentData from '../../data/EventAndEntertainmentServices.json';
import Modal from '../../components/PopUpModel/PopUpModal.tsx';
import type {
  EventAndEntertainmentData,
  Ad,
  Subcategory,
} from '../../types/EventAndEntertainmentServices.types.ts';

const EventAndEntertainmentServices: React.FC = () => {
  // Type data as EventAndEntertainmentData, accessing the first element of the array
  const data: EventAndEntertainmentData =
    eventAndEntertainmentData as unknown as EventAndEntertainmentData;
  const [selectedAd, setSelectedAd] = useState<Ad | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);

  const openModal = (ad: Ad) => {
    setSelectedAd(ad);
    setModalVisible(true);
  };

  const closeModal = () => {
    setSelectedAd(null);
    setModalVisible(false);
  };
  const renderField = (field: string, value: any) => {
    if (Array.isArray(value) && field === 'uploadPhotos') {
      // Render images if the field is uploadPhotos
      return (
        <div className="ad-photos">
          {value.map((photoUrl: string, index: number) => (
            <img key={index} src={photoUrl} alt="Ad Photo" className="ad-photo-modal" />
          ))}
        </div>
      );
    } else if (typeof value === 'string' && value.startsWith('http')) {
      // Render clickable link for URLs
      return (
        <a href={value} target="_blank" rel="noopener noreferrer">
          {value}
        </a>
      );
    } else if (typeof value === 'string' && field.toLowerCase().includes('email')) {
      // Render clickable email link for email addresses
      return <a href={`mailto:${value}`}>{value}</a>;
    } else {
      // Render other fields as plain text
      return <span>{value}</span>;
    }
  };

  return (
    <div className="all-ads">
      {data.map((categoryData, categoryIndex) => (
        <div key={categoryIndex}>
          {Object.entries(categoryData['Event and Entertainment Services']).map(
            ([subcategoryName, adGroups]) => (
              <div key={subcategoryName} className="subcategory-section">
                <h3>{subcategoryName}</h3>
                {Object.entries(adGroups as Subcategory).map(([adGroupKey, ads]) => (
                  <div key={adGroupKey} className="ads-list">
                    {Array.isArray(ads) ? (
                      ads.map((ad: Ad, index: number) => (
                        <div key={index} className="ad-card" onClick={() => openModal(ad)}>
                          <div className="photo-gallery">
                            {ad.uploadPhotos && ad.uploadPhotos.length > 0 && (
                              <img
                                src={ad.uploadPhotos[0]}
                                alt={`${ad.title} photo`}
                                className="ad-photo"
                              />
                            )}
                          </div>
                          <h4>{ad.title}</h4>
                          <p>{ad.description}</p>
                          <p>
                            <strong>Price:</strong> {ad.priceRate}
                          </p>
                        </div>
                      ))
                    ) : (
                      <p>No ads available for this group.</p>
                    )}
                  </div>
                ))}
              </div>
            ),
          )}
          {selectedAd && (
            <Modal isVisible={isModalVisible} onClose={closeModal}>
              <div className="ad-details">
                {Object.entries(selectedAd).map(([field, value]) => (
                  <div key={field} className="ad-field">
                    <strong>{field}:</strong> {renderField(field, value)}
                  </div>
                ))}
              </div>
            </Modal>
          )}
        </div>
      ))}
    </div>
  );
};

export default EventAndEntertainmentServices;
