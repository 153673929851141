// src/infrastructure/api/axios.ts
import type { AxiosError } from 'axios';
import axios from 'axios';

const api = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    // Convert request data to x-www-form-urlencoded format
    if (config.data && config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
      const formData = new URLSearchParams();
      Object.entries(config.data).forEach(([key, value]) => {
        formData.append(key, value as string);
      });
      config.data = formData;
    }

    // Log request in development
    if (import.meta.env.DEV) {
      console.log('Request:', {
        url: config.url,
        method: config.method,
        data: config.data,
        headers: config.headers,
      });
    }

    return config;
  },
  (error: AxiosError) => {
    console.error('Request Error:', error);
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    // Log response in development
    if (import.meta.env.DEV) {
      console.log('Response:', {
        status: response.status,
        data: response.data,
        headers: response.headers,
      });
    }
    return response;
  },
  async (error: AxiosError) => {
    // Log error details in development
    if (import.meta.env.DEV) {
      console.error('Response Error:', {
        status: error.response?.status,
        data: error.response?.data,
        config: error.config,
      });
    }

    // Handle specific error cases
    //
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // Handle unauthorized
          console.error('Authentication failed:', error.response.data);
          break;
        case 403:
          // Handle forbidden
          console.error('Access forbidden:', error.response.data);
          break;
        case 404:
          // Handle not found
          console.error('Resource not found:', error.response.data);
          break;
        case 500:
          // Handle server error
          console.error('Server error:', error.response.data);
          break;
        default:
          console.error('Request failed:', error.response.data);
      }
    } else if (error.request) {
      // Handle network errors
      console.error('Network error:', error.message);
    } else {
      console.error('Error:', error.message);
    }

    return Promise.reject(error);
  },
);

// Add type for API error responses
export interface ApiError {
  message: string;
  code?: string;
  details?: unknown;
}

// Helper to extract error message
export const getErrorMessage = (error: unknown): string => {
  if (axios.isAxiosError(error)) {
    return (error.response?.data as ApiError)?.message || error.message;
  }
  return error instanceof Error ? error.message : 'An unknown error occurred';
};

export default api;
