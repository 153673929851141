import type { Category } from '../types/category.types.ts';

export const categories: Category[] = [
  {
    categoryName: 'Home Services',
    totalAds: 100,
    image: '/src/assets/Home Services.png',
    subCategories: [
      {
        subCategoryName: 'Cleaning Services',
        totalAds: 30,
        subSubCategories: [
          { subCategoryName: 'Home Cleaning', totalAds: 10 },
          { subCategoryName: 'Carpet Cleaning', totalAds: 5 },
        ],
      },
      {
        subCategoryName: 'Repair and Maintenance',
        totalAds: 40,
        subSubCategories: [
          { subCategoryName: 'Plumbing Services', totalAds: 10 },
          { subCategoryName: 'Electrical Services', totalAds: 15 },
          { subCategoryName: 'Handyman Services', totalAds: 5 },
          { subCategoryName: 'Appliance Repairs', totalAds: 5 },
          { subCategoryName: 'Furniture Repairs', totalAds: 5 },
        ],
      },
      {
        subCategoryName: 'Interior and Exterior',
        totalAds: 40,
        subSubCategories: [
          { subCategoryName: 'Interior Design', totalAds: 10 },
          { subCategoryName: 'Painting Services', totalAds: 15 },
          { subCategoryName: 'Landscaping Services', totalAds: 5 },
          { subCategoryName: 'Security Installations', totalAds: 5 },
          { subCategoryName: 'Water Purifier Services', totalAds: 5 },
        ],
      },
      {
        subCategoryName: 'Pest Control',
        totalAds: 30,
        subSubCategories: [
          { subCategoryName: 'Residential Pest Control', totalAds: 10 },
          { subCategoryName: 'Commercial Pest Control', totalAds: 20 },
        ],
      },
    ],
  },
  {
    categoryName: 'Education and Learning',
    totalAds: 120,
    image: '/src/assets/Education and Learning.png',
    subCategories: [
      {
        subCategoryName: 'Tutoring and Coaching',
        totalAds: 60,
        subSubCategories: [
          { subCategoryName: 'School Tutors', totalAds: 20 },
          { subCategoryName: 'College Coaching', totalAds: 15 },
          { subCategoryName: 'Foreign Language Classes', totalAds: 15 },
        ],
      },
      {
        subCategoryName: 'Job Training',
        totalAds: 30,
        subSubCategories: [
          { subCategoryName: 'IT and Software Training', totalAds: 10 },
          { subCategoryName: 'Digital Marketing Training', totalAds: 20 },
        ],
      },
      {
        subCategoryName: 'Professional Development',
        totalAds: 30,
        subSubCategories: [
          { subCategoryName: 'Career Counseling', totalAds: 10 },
          { subCategoryName: 'Resume Writing Services', totalAds: 20 },
        ],
      },
    ],
  },
  {
    categoryName: 'Event and Entertainment Services',
    totalAds: 90,
    image: '/src/assets/Events and Entertainment.png',
    subCategories: [
      {
        subCategoryName: 'Event Planning',
        totalAds: 60,
        subSubCategories: [
          { subCategoryName: 'Event Planners', totalAds: 20 },
          { subCategoryName: 'Wedding Planners', totalAds: 15 },
        ],
      },
      {
        subCategoryName: 'Entertainment Services',
        totalAds: 30,
        subSubCategories: [
          { subCategoryName: 'DJ Services', totalAds: 10 },
          { subCategoryName: 'Party Rentals', totalAds: 10 },
          { subCategoryName: 'Photographers', totalAds: 10 },
          { subCategoryName: 'Videographers', totalAds: 10 },
        ],
      },
      {
        subCategoryName: 'Catering and Hospitality',
        totalAds: 55,
        subSubCategories: [
          { subCategoryName: 'Catering Services', totalAds: 20 },
          { subCategoryName: 'Bartending Services', totalAds: 35 },
        ],
      },
    ],
  },
  {
    categoryName: 'Health and Wellness',
    totalAds: 120,
    image: '/src/assets/Health and wellness.png',
    subCategories: [
      {
        subCategoryName: 'Personal Fitness',
        totalAds: 60,
        subSubCategories: [
          { subCategoryName: 'Gyms', totalAds: 20 },
          { subCategoryName: 'Yoga Instructors', totalAds: 15 },
          { subCategoryName: 'Personal Trainers', totalAds: 15 },
        ],
      },
      {
        subCategoryName: 'Healthcare Services',
        totalAds: 30,
        subSubCategories: [
          { subCategoryName: 'Physiotherapy', totalAds: 10 },
          { subCategoryName: 'Mental Health Counseling', totalAds: 10 },
          { subCategoryName: 'Dieticians', totalAds: 10 },
        ],
      },
      {
        subCategoryName: 'Beauty and Personal Care',
        totalAds: 30,
        subSubCategories: [
          { subCategoryName: 'Hair Stylists', totalAds: 10 },
          { subCategoryName: 'Makeup Artists', totalAds: 10 },
          { subCategoryName: 'Beauty Parlors', totalAds: 10 },
          { subCategoryName: 'Massage Therapists', totalAds: 10 },
        ],
      },
    ],
  },
  {
    categoryName: 'Automotive Services',
    totalAds: 120,
    image: '/src/assets/Automotive.png',
    subCategories: [
      {
        subCategoryName: 'Vehicle Repair and Maintenance',
        totalAds: 60,
        subSubCategories: [
          { subCategoryName: 'Car Repairs', totalAds: 20 },
          { subCategoryName: 'Motorcycle Repairs', totalAds: 15 },
          { subCategoryName: 'Mechanics', totalAds: 15 },
        ],
      },
      {
        subCategoryName: 'Driving and Rentals',
        totalAds: 30,
        subSubCategories: [
          { subCategoryName: 'Driving Schools', totalAds: 10 },
          { subCategoryName: 'Car Rentals', totalAds: 10 },
        ],
      },
    ],
  },
  {
    categoryName: 'Real Estate',
    totalAds: 190,
    image: '/src/assets/Real Estate.png',
    subCategories: [
      {
        subCategoryName: 'Property for Sale',
        totalAds: 100,
        subSubCategories: [
          { subCategoryName: 'Houses for Sale', totalAds: 20 },
          { subCategoryName: 'Apartments for Sale', totalAds: 15 },
          { subCategoryName: 'Commercial Properties', totalAds: 15 },
        ],
      },
      {
        subCategoryName: 'Property for Rent',
        totalAds: 30,
        subSubCategories: [
          { subCategoryName: 'Houses for Rent', totalAds: 10 },
          { subCategoryName: 'Apartments for Rent', totalAds: 15 },
          { subCategoryName: 'PG Accommodation', totalAds: 15 },
          { subCategoryName: 'Shared Rentals (Roommates)', totalAds: 15 },
          { subCategoryName: 'Vacation Rentals', totalAds: 15 },
          { subCategoryName: 'Parking Spaces for Rent', totalAds: 15 },
        ],
      },
      {
        subCategoryName: 'Real Estate Services',
        totalAds: 30,
        subSubCategories: [
          { subCategoryName: 'Real Estate Agents', totalAds: 10 },
          { subCategoryName: 'Property Management', totalAds: 10 },
        ],
      },
    ],
  },
  {
    categoryName: 'Jobs and Employment',
    totalAds: 190,
    image: '/src/assets/Jobs and Employment.png',
    subCategories: [
      {
        subCategoryName: 'Job Listings',
        totalAds: 100,
        subSubCategories: [
          { subCategoryName: 'Full-time Jobs', totalAds: 20 },
          { subCategoryName: 'Part-time Jobs', totalAds: 15 },
          { subCategoryName: 'Freelance Jobs', totalAds: 15 },
          { subCategoryName: 'Temporary Jobs', totalAds: 15 },
        ],
      },
      {
        subCategoryName: 'Job Services',
        totalAds: 30,
        subSubCategories: [
          { subCategoryName: 'Job Placement Agencies', totalAds: 10 },
          { subCategoryName: 'Internships', totalAds: 15 },
          { subCategoryName: 'Work from Home Jobs', totalAds: 15 },
          { subCategoryName: 'Job Training', totalAds: 15 },
        ],
      },
    ],
  },
  {
    categoryName: 'Buy and Sell',
    totalAds: 190,
    image: '/src/assets/Buy and Sell.png',
    subCategories: [
      {
        subCategoryName: 'Electronics and Appliances',
        totalAds: 100,
        subSubCategories: [
          { subCategoryName: 'Buy/Sell New Electronics', totalAds: 20 },
          { subCategoryName: 'Buy/Sell Old Electronics', totalAds: 15 },
          { subCategoryName: 'Buy/Sell New Appliances', totalAds: 15 },
          { subCategoryName: 'Buy/Sell Old Appliances', totalAds: 15 },
        ],
      },
      {
        subCategoryName: 'Furniture',
        totalAds: 30,
        subSubCategories: [
          { subCategoryName: 'Buy/Sell New Furniture', totalAds: 10 },
          { subCategoryName: 'Buy/Sell Old Furniture', totalAds: 15 },
        ],
      },
      {
        subCategoryName: 'Vehicles',
        totalAds: 30,
        subSubCategories: [
          { subCategoryName: 'Buy/Sell New Cars', totalAds: 10 },
          { subCategoryName: 'Buy/Sell Used Cars', totalAds: 10 },
        ],
      },
      {
        subCategoryName: 'Antiques and Collectibles',
        totalAds: 30,
        subSubCategories: [
          { subCategoryName: 'Buy/Sell New', totalAds: 10 },
          { subCategoryName: 'Buy/Sell Used', totalAds: 10 },
        ],
      },
      {
        subCategoryName: 'Books and Media',
        totalAds: 30,
        subSubCategories: [
          { subCategoryName: 'Buy/Sell New', totalAds: 10 },
          { subCategoryName: 'Buy/Sell Used', totalAds: 10 },
        ],
      },
      {
        subCategoryName: 'Clothing and Fashion',
        totalAds: 30,
        subSubCategories: [
          { subCategoryName: 'Buy/Sell New', totalAds: 10 },
          { subCategoryName: 'Buy/Sell Used', totalAds: 10 },
        ],
      },
    ],
  },
  {
    categoryName: 'Personal Care Services',
    totalAds: 190,
    image: '/src/assets/Personal Care Services.png',
    subCategories: [
      {
        subCategoryName: 'Health and Beauty',
        totalAds: 100,
        subSubCategories: [
          { subCategoryName: 'Hair Cutting Services', totalAds: 20 },
          { subCategoryName: 'Makeup Artists', totalAds: 15 },
          { subCategoryName: 'Beauty Services', totalAds: 15 },
        ],
      },
      {
        subCategoryName: 'Personal Services',
        totalAds: 30,
        subSubCategories: [
          { subCategoryName: 'Babysitting Services', totalAds: 10 },
          { subCategoryName: 'Dog Walking Services', totalAds: 15 },
          { subCategoryName: 'Pet Sitting Services', totalAds: 15 },
        ],
      },
      {
        subCategoryName: 'Wellness',
        totalAds: 30,
        subSubCategories: [
          { subCategoryName: 'Massage Therapy', totalAds: 10 },
          { subCategoryName: 'Diet and Nutrition', totalAds: 10 },
        ],
      },
    ],
  },
  {
    categoryName: 'Business and Professional Services',
    totalAds: 190,
    image: '/src/assets/Business and Personal Services.png',
    subCategories: [
      {
        subCategoryName: 'Consulting',
        totalAds: 100,
        subSubCategories: [
          { subCategoryName: 'Legal Consultants', totalAds: 20 },
          { subCategoryName: 'Financial Consultants', totalAds: 15 },
          { subCategoryName: 'Immigration Consultants', totalAds: 15 },
        ],
      },
      {
        subCategoryName: 'Logistics',
        totalAds: 30,
        subSubCategories: [
          { subCategoryName: 'Packers and Movers', totalAds: 10 },
          { subCategoryName: 'Courier Services', totalAds: 15 },
          { subCategoryName: 'Recycling Services', totalAds: 15 },
          { subCategoryName: 'Agricultural Equipment', totalAds: 15 },
        ],
      },
      {
        subCategoryName: 'Business Services',
        totalAds: 30,
        subSubCategories: [
          { subCategoryName: 'Digital Marketing Services', totalAds: 10 },
          { subCategoryName: 'Web Development Services', totalAds: 10 },
          { subCategoryName: 'IT Services', totalAds: 15 },
          { subCategoryName: 'Graphic Designers', totalAds: 15 },
        ],
      },
    ],
  },
  {
    categoryName: 'Matrimonial and Personal',
    totalAds: 190,
    image: '/src/assets/Matrimonial and Personal.png',
    subCategories: [
      {
        subCategoryName: 'Matrimonial Services',
        totalAds: 100,
        subSubCategories: [
          { subCategoryName: 'Wedding Planners', totalAds: 20 },
          { subCategoryName: 'Matchmaking Services', totalAds: 15 },
        ],
      },
      {
        subCategoryName: 'Marriage Services',
        totalAds: 40,
        subSubCategories: [
          { subCategoryName: 'Wedding Venue Rentals', totalAds: 10 },
          { subCategoryName: 'Wedding Catering', totalAds: 15 },
          { subCategoryName: 'Marriage Counseling', totalAds: 15 },
        ],
      },
    ],
  },
  {
    categoryName: 'Travel and Tourism',
    totalAds: 190,
    image: '/src/assets/Travel and Tourism.png',
    subCategories: [
      {
        subCategoryName: 'Vehicle Rentals',
        totalAds: 100,
        subSubCategories: [
          { subCategoryName: 'Car Rentals', totalAds: 20 },
          { subCategoryName: 'Bike Rentals', totalAds: 15 },
        ],
      },
      {
        subCategoryName: 'Travel Services',
        totalAds: 30,
        subSubCategories: [
          { subCategoryName: 'Tour Operators', totalAds: 10 },
          { subCategoryName: 'Travel Agents', totalAds: 15 },
          { subCategoryName: 'Visa Services', totalAds: 15 },
        ],
      },
    ],
  },

  // Add more categories similarly...
];
