// src/features/auth/components/LoginForm/LoginForm.demo.tsx

import React from 'react';
import PropTypes from 'prop-types';
import type { LoginFormProps } from './LoginForm';
import { LoginForm } from './LoginForm';

export const loginFormDemo: React.FC<LoginFormProps> = (props) => {
  return <LoginForm {...props} />;
};

loginFormDemo.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};
