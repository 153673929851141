import React, { useEffect, useState } from 'react';
import { fetchLogo } from '../../api/logo.api';
import type { LogoProps } from '../../types/logo.types';

const Logo: React.FC<LogoProps> = ({ altText, className }) => {
  const [logoUrl, setLogoUrl] = useState<string | null>(null);

  useEffect(() => {
    const loadLogo = async () => {
      try {
        const logo = await fetchLogo();
        setLogoUrl(logo.url);
      } catch (error) {
        console.error('Failed to fetch logo:', error);
      }
    };
    loadLogo();
  }, []);

  return (
    <div className={className}>
      {logoUrl ? (
        <img src={logoUrl} alt={altText} style={{ height: '50px', width: '50px' }} />
      ) : (
        <span>Loading...</span>
      )}
    </div>
  );
};

export default Logo;
