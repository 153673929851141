import React from 'react';
import '../../CSS/HSCategory.css';
import type {
  HomeServicesData,
  HomeServiceAd,
  HomeServiceDetails,
} from '../../types/homeServices.types.ts';

interface HSCategoryProps {
  data: HomeServicesData;
}

const HSCategory: React.FC<HSCategoryProps> = ({ data }) => {
  return (
    <div className="hs-category-container">
      <h2>Home Services</h2>
      <div className="service-grid">
        {data.map((service: HomeServiceAd) =>
          service.homeServicesAd.map((ad: HomeServiceDetails, index) => (
            <div key={`${service.metadata.id}-${index}`} className="service-card">
              <h3 className="service-title">{service.metadata.title}</h3>
              <p className="service-description">{service.metadata.description}</p>
              <p className="service-category">
                <strong>Category:</strong> {service.metadata.subCategory}
              </p>
              <h4>Service Type: {ad.serviceType}</h4>
              <p className="ad-price">
                <strong>Price:</strong> ${ad.price}
              </p>
              <p className="ad-location">
                <strong>Location:</strong> {ad.location.city}, {ad.location.state},{' '}
                {ad.location.country}
              </p>
              <p className="ad-info">
                <strong>Details:</strong> {ad.serviceDetails.join(', ')}
              </p>
            </div>
          )),
        )}
      </div>
    </div>
  );
};

export default HSCategory;
