import React from 'react';
import { useParams } from 'react-router-dom';
import { categories } from '@/features/auth/data/categoryData.ts';

const SubcategoryPage: React.FC = () => {
  const { categoryName } = useParams<{ categoryName: string }>();

  // Find the category data based on the category name from params
  const category = categories.find((cat) => cat.categoryName === categoryName);

  if (!category) {
    return <p>Category not found</p>;
  }

  return (
    <div className="subcategory-page">
      <h2>{category.categoryName}</h2>
      <p>Total Ads: {category.totalAds}</p>

      <div className="subcategory-list">
        {category.subCategories.map((subCategory, index) => (
          <div key={index} className="subcategory-item">
            <h3>{subCategory.subCategoryName}</h3>
            <p>{subCategory.totalAds} Ads</p>
            <div className="sub-subcategory-list">
              {/* Use optional chaining to safely access subSubCategories */}
              {subCategory.subSubCategories?.map((subSubCategory, idx) => (
                <div key={idx} className="sub-subcategory-item">
                  <p>
                    {subSubCategory.subCategoryName} - {subSubCategory.totalAds} Ads
                  </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubcategoryPage;
