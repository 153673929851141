import React from 'react';
import type { RealEstateAd } from '@/types/ads/RealEstateAd';
import '../../CSS/RealEstatePage.css';

interface WishlistProps {
  likedItems: RealEstateAd[];
  handleLoveClick: (listing: RealEstateAd) => void;
}

const Wishlist: React.FC<WishlistProps> = ({ likedItems, handleLoveClick }) => {
  return (
    <div className="wishlist">
      <h2>Saved Ads</h2>
      {likedItems.length === 0 ? (
        <p>No items in wishlist</p>
      ) : (
        <div className="wishlist-grid">
          {likedItems.map((item) => (
            <div key={item.metadata.id} className="wishlist-item">
              <h3>{item.metadata.title}</h3>
              <p>{item.metadata.description}</p>
              <p>Price: ${item.realEstateAd.price}</p>
              <p>
                Location: {item.realEstateAd.location.city}, {item.realEstateAd.location.state}
              </p>
              <span className="love-icon red" onClick={() => handleLoveClick(item)}>
                ❤️
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Wishlist;
