import React, { useState } from 'react';
import { registerUser } from '../../api/auth.api';
import type { RegisterCredentials } from '../../types/auth.types';
import '../../CSS/registrationstyle.css';
import { useNavigate, useLocation } from 'react-router-dom';

interface RegistrationFormProps {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}

export const Registrationform: React.FC<RegistrationFormProps> = ({ onSuccess, onError }) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Retrieve the redirect path from the location state or set it to home by default
  const redirectTo = location.state?.redirectTo || '/';

  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    // Validate email format
    if (!validateEmail(email)) {
      setError('Invalid email format');
      setLoading(false);
      return;
    }

    // Prepare registration credentials
    const credentials: RegisterCredentials = { username, email, password };
    console.log('Sending registration payload:', credentials);

    try {
      const response = await registerUser(credentials);
      onSuccess?.();
      console.log('Registration successful:', response);

      // Redirect back to the original page or to home if not specified
      navigate(redirectTo, { state: { isLoggedIn: true } });
    } catch (err) {
      const error = err as Error;
      setError(error.message);
      onError?.(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit} className="space-y-4 w-full max-w-md">
        <h2>Signup</h2>
        <div className="space-y-2">
          <label htmlFor="username" className="label">
            Full Name
          </label>
          <input
            id="username"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="input-field"
            placeholder="Full Name"
            required
          />
        </div>

        <div className="space-y-2">
          <label htmlFor="email" className="label">
            Email
          </label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="input-field"
            placeholder="email@example.com"
            required
          />
        </div>

        <div className="space-y-2">
          <label htmlFor="password" className="label">
            Password
          </label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input-field"
            placeholder="Password"
            required
          />
        </div>

        {error && <div className="error-message">{error}</div>}

        <button type="submit" disabled={loading} className="submit-button">
          {loading ? 'Registering...' : 'Sign Up'}
        </button>
      </form>
    </div>
  );
};
