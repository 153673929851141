import React from 'react';
import ReactDOM from 'react-dom/client';
import { componentRegistry } from './demo/registry/ComponentRegistry';
import { loginFormDemo } from '@features/auth/components/LoginForm/LoginForm.demo';
import App from '@/App.tsx';

// Register components
componentRegistry.register('login-form', {
  title: 'Login Form',
  component: loginFormDemo,
  path: '/login-form',
  parameters: {
    docs: {
      description: 'Demo for the Login Form component',
    },
  },
  argTypes: {
    onSuccess: { action: 'onSuccess' },
    onError: { action: 'onError' },
  },
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
