import type { RegisterCredentials, LoginCredentials, LoginResponse } from '../types/auth.types';
import api, { getErrorMessage } from '@infrastructure/api/axios.ts';

// // Helper function to handle fetch responses
// const handleResponse = async (response: Response) => {
//     if (!response.ok) {
//         const error = await response.json();
//         throw new Error(error.message || 'Something went wrong');
//     }
//     return response.json();
// };

// Register User
export const registerUser = async (credentials: RegisterCredentials) => {
  try {
    const response = await api.post<LoginResponse>('/users/create', credentials, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.error('response.data=', response.data); // i added the .error inplace of the  .log
    return response.data; // Return the actual response data instead of hardcoded 'Success'
  } catch (error) {
    console.error('Error registering user:', error);
    throw new Error(getErrorMessage(error));
  }
};

export const loginUser = async (credentials: LoginCredentials): Promise<LoginResponse> => {
  try {
    const response = await api.post<LoginResponse>('/users/login', credentials);
    return response.data;
  } catch (error) {
    console.error('Error while loginUser:', error);
    throw new Error(getErrorMessage(error));
  }
};
