import categoriesData from './categoriesData.json';
import './Categories.css';
import type { Category } from '@features/auth/types/category.types.ts';

interface DropdowncategoryiesProps {
  categories?: Category[];
}

// eslint-disable-next-line no-empty-pattern
const Categories = ({}: DropdowncategoryiesProps) => {
  return (
    <div className="categories-container">
      {categoriesData.categories.map((category, index) => (
        <div key={index} className="category-column">
          <h3 className="category-name">{category.categoryName}</h3>
          <ul className="subcategories-list">
            {category.subCategories.map((subCategory, idx) => (
              <li key={idx} className="subcategory-item">
                {subCategory}
              </li>
            ))}
          </ul>
        </div>
      ))}
      <div className="see-all">
        <a href="/all-categories" className="see-all-link">
          See all categories &gt;
        </a>
      </div>
    </div>
  );
};

export default Categories;
