// Footer.tsx
import React from 'react';
import '../../CSS/Footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        {/* Logo and About */}

        <div className="quick-links">
          {/* Quick Links */}
          <div className="footer-section-links">
            <h3>Quick Links</h3>
            <ul>
              <li>
                <a href="/about">About Us</a>
              </li>
              <li>
                <a href="/contact">Contact Us</a>
              </li>
              <li>
                <a href="/privacy">Privacy Policy</a>
              </li>
              <li>
                <a href="/terms">Terms of Service</a>
              </li>
              <li>
                <a href="/faq">FAQ</a>
              </li>
            </ul>
          </div>

          {/* Contact Information */}
          <div className="footer-section-contact">
            <h3>Contact Us</h3>
            <ul>
              <li>
                <a href="tel:+1234567890">+1 234 567 890</a>
              </li>
              <li>
                <a href="mailto:support@adzmonster.com">support@adzmonster.com</a>
              </li>
              <li>
                <a href="https://goo.gl/maps/address" target="_blank" rel="noopener noreferrer">
                  123 Monster Street, AdzCity, CA
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-section-about">
        <h2>AdzMonster</h2>
      </div>
      {/* Footer Bottom */}
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} AdzMonster. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
